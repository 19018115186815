<template>
  <div v-if="item !== null">
    <h1 v-text="item.title"></h1>
    <h6 class="font-weight-bold" v-text="humanDate(item.created)"></h6>
    <div v-html="item.content"></div>
  </div>
</template>
<script>

const axios = require('axios')
const  moment = require('moment')

moment.locale('ru')
export default {
  name: 'NewsItem',
  components: {},
  props: {

  },

  data() {
    return {
      leftMenu: [],
      item: null,
    }
  },


  mounted() {
    this.getItem()
  },
  created() {

  },
  methods: {
    getItem() {
      let self = this
      let url = '/api/news/' + self.$route.params.pk + '/'

      axios.get(url).then(function (resp) {
        self.item = resp.data
        self.$parent.$children[0].$data.nYt = self.item.title

      }).catch(function (err) {
        console.log(err)
      })
    },
    humanDate(dt){
      let ts = moment(dt)
      console.log(ts)
      return ts.fromNow()
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        console.log(to)
      }
    }
  }
}
</script>
