<template>
  <div id="content">
    <div v-html="article.content" v-if="!isRunTime" ></div>
    <v-runtime-template v-if="isRunTime" :template="article.content" ></v-runtime-template>
    <div class="row" v-if="article.nav">
      <div class="col-sm-12 col-md-6 mb-3">
        <b-button v-b-modal.modal-service
                variant="warning" v-if="!isSm">Заказать услугу</b-button>
        <b-button v-b-modal.modal-service block
                variant="warning" v-else>Заказать услугу</b-button>
      </div>
      <div class="col-sm-12 col-md-6 text-right mb-3">
        <router-link :to="'/reviews/'" class="btn btn-green d-block d-md-inline-block" >
        Нас рекомендуют
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint no-inner-declarations:0*/
const article = JSON.parse(document.getElementById('article').innerText)
const axios = require('axios')
import 'responsive-tables-js/src/responsivetables.css'
let articlesX = {}
const allArticles = JSON.parse(document.getElementById('articles').innerText)
for (let c = 0; c < allArticles.length; c++) {
  allArticles[c].content = null
  let _path = allArticles[c].path
  articlesX[_path] = allArticles[c]
}
/* eslint-disable no-unused-vars */
function trim(s, c) {
  if (c === "]") c = "\\]";
  if (c === "^") c = "\\^";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
      "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
}

export default {
  name: 'Article',
  components: {},
  props: {},

  data() {
    return {
      article: article,
      allArticles: allArticles,
      articlesX: articlesX,
      leftMenu: {},
      mapJS: false,
      mapCreated: false,
      maps: {},
      isRunTime: true,
      latestNews:[],
      showAll: false,
      toggleText: 'Показать все',
      isSm: false
    }
  },

  mounted() {
    if(window.innerWidth < 576) {
      this.isSm = true
    } else {
      this.isSm = false
    }
  },
  created() {
  },
  updated() {
    let self = this;
    // console.log(44, document.getElementById('content').innerHTML)
    // console.log(document.getElementById('myTable'))
    self.maps = {}
    if(document.querySelectorAll('.ymap').length > 0){
      if(window.ymaps === undefined){
        let js = document.createElement('script')
        js.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU'
        js.onload = function () {
          window.ymaps.ready(function () {
            self.mapJS =true
          })
        }
        document.getElementsByTagName('head')[0].appendChild(js)
        function initMap(){
          let mapEl
          let coordinates
          let zoom
          let balloon
          let hint
          let lbl
          for (let c = 0; c < document.querySelectorAll('.ymap').length; c++) {
            mapEl = document.querySelectorAll('.ymap')[c];
            mapEl.id = 'map-' + c
            console.log(mapEl)
            if(mapEl.dataset.coordinates === undefined){
              continue
            }
            if(self.maps['map-'+c] !== undefined) {
              continue
            }
            coordinates = mapEl.dataset.coordinates.split(',')
            zoom = mapEl.dataset.zoom
            balloon = mapEl.dataset.balloon
            hint = mapEl.dataset.hint
            self.maps['map-'+c] = new window.ymaps.Map('map-' + c, {
                center: coordinates, zoom: zoom
            })
            lbl = {
              hintContent: hint,
              balloonContent: '<h5>' + hint + '</h5>' + '<p>' + balloon +'</p>'
            }

            self.maps['map-'+c].geoObjects.add(new window.ymaps.Placemark(coordinates, lbl));
            self.maps['map-'+c].container.fitToViewport();
          }
        }
        let int = setInterval(function () {
          if(self.mapJS && window.ymaps !== undefined && !self.mapCreated) {
            initMap()
            self.mapCreated = true
            clearInterval(int)
          }
        }, 50)
      }
    }

  },
  methods: {
    toggleMore(event){
      event.preventDefault()
      this.showAll = !this.showAll
      if(this.showAll) {
        this.toggleText = 'Скрыть'
      } else {
        this.toggleText = 'Показать все'
      }
    },
    openServiceModal(){

    }
  },
  watch: {
    $route: {

      immediate: true,
      handler(to) {
        let self = this
        if (to === undefined) {
          return;
        }
        let articlePath = to.fullPath
        if (articlePath == '/') {
          articlePath = ''
        }
        this.article = this.articlesX[trim(articlePath, '/')]


        document.querySelector('meta[name="description"]').content = self.article.meta_description
        document.title = self.article.title
        if (!this.article.content) {
          axios.get(`/api/article/${self.article.id}/`).then(function (resp) {
            let article = resp.data
            if (article.content.indexOf('countdown-timer') !== -1
                || article.content.indexOf('toggleMore') !== -1){
              self.isRunTime = true
            } else {
              self.isRunTime = false
            }
            self.article = article
            self.articlesX[articlePath] = article

          }).catch(function (err) {
            console.log(err)
          })
        }
      }
    }
  }
}
</script>
<style>

#content {
}
.ymap {
  height: 400px;
  overflow: hidden;
  margin-bottom: 1rem;
}
#readMore {
  font-weight: bold;
}
.card-img-top {
  transition: 0.2s;
}
.card-img-top:hover {
  margin-top: -10px;
  padding-bottom: 10px;
}
.btn-green {
  background: #BBDDBD;
}
</style>

