<template>
  <div class="row">
    <div class="pl-3 w-100">
      <div class="col-xs-12" v-if="threads.length === 0">
        Нет активных разговоров
      </div>
      <div class="chat-admin col-xs-12" v-else>
        <b-tabs>
          <b-tab v-for="thread in threads" :key="thread.uuid" @click="openThread(thread)">
            <template #title>
              {{ formatTime(thread.created_at) }}
              <span v-if="unReadCount(thread) > 0" class="btn btn-sm btn-danger" v-text="unReadCount(thread)"></span>
              <button type="button" aria-label="Close" class="close" @click="closeThread(thread)">×</button>
            </template>
            <div class="chat col-xs-12">
              <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
              <div class="chat-body">
                <div v-for="m in thread.messages" v-bind:key="m.id"
                     class="message" :class="m.is_reply?  '':'reply'">
                  <div v-text="m.text"></div>
                  <div class="text-right">
                    <span v-text="!m.is_reply? 'Посетитель' : 'Cисадмин'"></span>
                    <span class="ts" v-text="formatTime(m.ts)" v-if="m.ts"></span>
                  </div>
                </div>
              </div>
              <div class="chat-footer ">
                <div class="form-group">
                  <textarea v-model="text[thread.uuid]" placeholder="Введите сообщение"
                            class="form-control"></textarea>
                </div>
                <div class="text-right form-group">
                  <button class="btn btn-info" @click="sendMessage(thread)">Отправить</button>
                </div>
              </div>
            </div>

          </b-tab>
        </b-tabs>
      </div>

    </div>
  </div>

</template>
<script>
const axios = require('axios')
const moment = require('moment')
// const urlPrefix = 'ws://' + window.location.host +'/ws/chat/'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

export default {
  name: 'ChatAdmin',
  components: {},
  props: {},

  data() {
    return {
      threads: [],
      threadsX: {},
      inter: null,
      text: {},
      massagesCount: {},
      readCount: {}
    }
  },

  mounted() {
    let self = this
    self.readCount = JSON.parse(localStorage.getItem('chat.readCount')) || {}
    axios.get('/api/check-user/').then(function (resp) {
      if (resp.data !== 'Ok') {
        window.location.href = '/'
        return
      } else {
        self.inter = setInterval(function () {
          self.getThreads()
        }, 1500)

      }
    }).catch(function () {
      window.location.href = '/'
    })
  },
  created() {

  },
  methods: {
    unReadCount(thread) {
      let res = thread.messages.length
      if (this.readCount[thread.uuid] === undefined) {
        return res
      }
      return res - this.readCount[thread.uuid]
    },
    openThread(thread){
      this.readCount[thread.uuid] = thread.messages.length
      localStorage.setItem("chat.readCount", JSON.stringify(this.readCount));
    },
    closeThread(thread){
      console.log(thread)
      let self = this
      axios.delete(`/api/chat-admin/${thread.uuid}/` ).then(function (resp) {
        if(resp.data.success){
          let idx = self.threadsX[thread.uuid]
          self.threads.splice(idx, 1)
        }
      }).catch(function (err) {
        console.log(err)
      })
    },
    getThreads(){
      let self = this
      axios.get('/api/chat-admin/').then(function (resp) {
          self.threads = resp.data
          resp.data.forEach(function (e, i) {
            self.threadsX[e.uuid] = i
            // self.text[e.uuid] = ''
          })
        }).catch(function (err) {
          console.log(err)
        })
    },
    sendMessage(thread) {
      let self = this
      axios.post('/api/chat/', {
        text: self.text[thread.uuid],
        thread: thread.uuid
      }).then(
          function (resp) {
            let text = JSON.parse(JSON.stringify(self.text))
            // console.log(text)
            self.threads = resp.data
            resp.data.forEach(function (e, i) {
              self.threadsX[e.uuid] = i
            })
            self.text[thread.uuid] = ''
            self.text = text

          }
      ).catch(function (err) {
        console.log(err)
      })
    },
    formatTime(ts) {
      return moment(ts).format('DD.MM.YYYY HH:mm')
    },

  },
  computed: {
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to === undefined) {
          return;
        }

      }
    },
  }
}
</script>
<style>

</style>
