import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSlickCarousel from 'vue-slick-carousel'
import YandexShare from '@cookieseater/vue-yandex-share';
const Paginate = require('vuejs-paginate')
import { library } from '@fortawesome/fontawesome-svg-core'
import {faAngleUp, faBackward, faForward, faPhone} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CountdownTimer from "./components/blocks/CountdownTimer.vue";
import VRuntimeTemplate from "v-runtime-template";
import MaskedInput from "vue-masked-input/src/MaskedInput";


library.add(faAngleUp)
library.add(faBackward)
library.add(faForward)
library.add(faPhone)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.component('VueSlickCarousel', VueSlickCarousel)
Vue.component('yandex-share', YandexShare);
Vue.component('paginate', Paginate)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('countdown-timer', CountdownTimer)
Vue.component('v-runtime-template', VRuntimeTemplate)
Vue.component('MaskedInput', MaskedInput)
Vue.use(IconsPlugin)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App, VueSlickCarousel, FontAwesomeIcon },
  template: '<App/>'
})
