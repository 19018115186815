<template>
  <div>
    <div class="row no-gutters" id="header-block">
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

      <div class="col-md-2 col-sm-12 col-xs-12">
        <router-link :to="'/'">
          <img src="/media/img/logo.gif" alt="sys-admin.by" class="img-fluid">
        </router-link>
      </div>
      <div class="col-md-10 col-xs-12 col-sm-12">
        <div class="row">
          <div class="col-md-8 d-none d-md-block top-carousel mt-2">
            <VueSlickCarousel :autoplay="true" :arrows="false" :autoplaySpeed="3000"
                              :dots="true" v-if="slides.length > 0">
              <div v-for="(s, idx) in slides" v-bind:key="idx" class="top-carousel-item">
                <img :src="s.bg_image" alt="Системный Администратор" class="bg-image">
                <img :src="s.text_image" alt="Системный Администратор" class="text-image">
                <img src="/static/img/call-me1.png" alt="Заказать звонок" class="text-image">
                <button class="btn btn-warning outline btn-call-modal" @click="openCallForm">
                  Заказать звонок</button>
              </div>
            </VueSlickCarousel>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 px-0 px-md-1">
            <div class="row no-gutters w-100 contacts-list">
              <div class="text-left w-50" v-for="g in phones" v-bind:key="g.id">
                  <div v-for="p in g.items" v-bind:key="p.id">
                    <img class="contact-icon" v-if="p.icon !== null" :alt="p.text"
                         v-bind:src="p.icon"><a v-bind:href="p.link" v-html="p.text"></a>
                  </div>
              </div>
            </div>
            <div class="text-center">
              <b-button @click="openCallForm"
                  class="btn btn-warning btn-sm btn-block">Заказать звонок</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-no-gutters bg-white" id="menu-container" style="z-index: 10000">

      <nav class="navbar w-100  bg-white  navbar-sa navbar-light navbar-expand-md"
           id="top-menu">
        <button class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation"
                @click="toggleNavBar"
                id="top-menu-toggler"
                >
          <span class="navbar-toggler-icon "></span>
        </button>
        <div class="navbar-collapse collapse " id="navbarSupportedContent">
          <ul class="navbar-nav" style="width: 100%">
            <li class="nav-item" data-dropdown="dropdown" v-for="m in topMenu" v-bind:key="m.id"
                :class="m.children.length > 0 ? 'dropdown': ''"
                @mouseenter="openDropDown"
                @mouseleave="closeDropDown"
            >
              <router-link
                  :to="'/' + m.slug + '/'" :data-slug="m.slug"
                  :data-hover="m.children.length ? 'dropdown': ''"
                           :class="'' + m.children.length ? 'has-children': ''"
              >{{ m.title }}
              </router-link>
              <span class="cp" @click="toggleThisDD"><i class="dropdown-toggle" v-if="m.children.length" @click="toggleThisDD(m)"></i></span>
              <ul v-if="m.children.length > 0" class="dropdown-menu" :id="'submenu-'+m.id">
                <li v-for="c in m.children" v-bind:key="c.id" v-if="!c.private || c.private && userStatus.isSuperuser" class="dropdown-item" >
                  <router-link v-if="c.slug" :to="'/' + c.slug + '/'">{{ c.title }}</router-link>
                  <a v-else :href="c.absolute_url" v-text="c.title" ></a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="row" v-if="branch && currentUrl != '/' && currentUrl != null">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <template v-if="currentUrl.indexOf('/news/') == -1">
          <li class="breadcrumb-item"><a href="/">Главная</a></li>
          <template v-if="branch.absolute_url == currentUrl">
            <li class="breadcrumb-item active" aria-current="page" v-text="branch.title"></li>
          </template>
          <template v-else>
            <li class="breadcrumb-item">
              <a :href="branch.absolute_url" v-text="branch.title"></a>
            </li>
            <template v-for="child in branch.children">
              <li v-if="child.absolute_url == currentUrl"
                  class="breadcrumb-item active" v-text="child.title"
                  v-bind:key="child.id"></li>
            </template>
          </template>
          </template>
          <template v-else>
            <li class="breadcrumb-item"><a href="/">Главная</a></li>
            <li class="breadcrumb-item"><a href="/informatsiia/">Информация</a></li>
            <li class="breadcrumb-item active" v-if="currentUrl == '/news/'">
              <span class="">Новости</span>
            </li>
            <li class="breadcrumb-item" v-if="currentUrl != '/news/'">
              <a href="/news/">Новости</a>
            </li>
            <li  class="breadcrumb-item"  v-if="currentUrl != '/news/'">
              <span>{{nYt}}</span>

            </li>
          </template>
        </ol>
      </nav>
    </div>
  </div>
</template>
<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

window.onscroll = function () {
  if (window.innerWidth < 768) {
    return
  }
  // console.log(window.scrollY)
  let menu = document.getElementById('menu-container')


  if(window.scrollY > document.getElementById('header-block').offsetHeight) {
    // console.log('larger')

    if(!menu.classList.contains('fixed-top')){
      menu.classList.add('fixed-top')
    }
  }  else {
    if(menu.classList.contains('fixed-top')){
      menu.classList.remove('fixed-top')
    }
  }
}

const phones = JSON.parse(document.getElementById('phones').innerText)
const menuItems = JSON.parse(document.getElementById('menus').innerText).top_menu
const slides = JSON.parse(document.getElementById('banners').innerText)
const userStatus = JSON.parse(document.getElementById('userStatus').innerText)
export default {
  comments: {VueSlickCarousel},
  name: 'Header',
  props: {
    topMenu: null,
    topMenuItems: null,
    branch: null,
    ddOPen: {}
  },
  data() {
    return {
      phones: [],
      menuItems: [],
      slides: [],
      currentBranch: null,
      userStatus: userStatus,
      currentUrl: null,
      nYt: ''
    }
  },
  methods: {
    openCallForm(){
      this.$emit('open-call-form')
    },
    toggleThisDD(menu){
      // console.log(menu)
      let el = document.getElementById('submenu-' + menu.id)
      if(el) {
        if(el.classList.contains('visible')) {
          el.classList.remove('visible')
          el.classList.add('invisible')
        } else {
          el.classList.add('visible')
          el.classList.remove('invisible')
        }
      }
      // console.log(el)
      },
    toggleNavBar(){
      let el = document.getElementById('navbarSupportedContent')
      if (el.classList.contains('show')) {
        el.classList.remove('show')
      } else {
        el.classList.add('show')
      }
    },
    openDropDown(){

    },
    closeDropDown(){
      let el = document.getElementById('navbarSupportedContent')
      if (el.classList.contains('show')) {
        el.classList.remove('show')
      }
    }
  },
  // rgd23AS81#w
  created() {
    this.currentBranch = this.branch
    this.phones = phones
    this.menuItems = menuItems
    this.slides = slides

  },
  mounted() {
    this.$emit('rendered', this.currentBranch)
    this.currentUrl = window.location.pathname

  },
  computed: {
    urlName: function () {
      return this.$parent.$router.history.current.name
    },
  },
  watch:{
    $route (to){
      this.currentUrl = to.fullPath
      this.closeDropDown()
    },
    newsItemTitle(){
      if (this.$parent.$children[2].$data.item !== undefined) {
        this.nYt = ''
        // return ''
      }
      this.nYt = this.$parent.$children[2].$data.item.title
      // return this.$parent.$children[2].$data.item.title

    }

}

}
</script>
<style>
.dropdown-toggle:after{
  border-top-color: #ef9c43 !important;
}
.has-children {
  font-size: large;
  font-weight: bold;
}
.contact-icon {
  max-width: 1.2rem;
      margin-top: 0.2rem;
    margin-right: 0.2rem;
}
#menu-container {
  max-width: 1140px;
}
#menu-container.fixed-top {
  margin: 0 auto;
}
#top-menu-toggler {
    position: absolute;
    top: -14rem;
    right: 0px;
}
i.dropdown-toggle {
z-index: 10000;
}
.dropdown-menu.visible {
  display: block !important;
}
.dropdown-menu.invisible {
  display: none !important;
}
.bg-image {
  height: 120% !important;
}
@media screen and (max-width: 321px) {
  .contacts-list {
    font-size: 0.85rem;
  }
}
.cp {
  cursor: pointer;
}
</style>
