import Vue from 'vue'
import Router from 'vue-router'
import Article from '../components/Article.vue'
import NewsList from "@/components/NewsList";
import NewsItem from "@/components/NewsItem";

// import HTMLItem from "../components/HTMLItem.vue"
import FAQTopicList from "../components/FAQTopicList.vue";
import FAQTopicDetail from "../components/FAQTopicDetail.vue";
import Chat from "../components/Chat.vue";
import ChatAdmin from "../components/ChatAdmin.vue";
// import list_to_tree from "../libs/utils.js";
const menus = JSON.parse(document.getElementById('menus').innerText)
Vue.use(Router)
const articles = JSON.parse(document.getElementById('articles').innerText)
let paths = []
articles.forEach(function (e) {
  if (e.path === '') {
    return
  }
  paths.push(e.path.split('/').join('/'))
})

console.log(paths)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Article,
      props: {sidebar: false, }
    },
    {
      path: '/news',
      name: 'news',
      component: NewsList,
      meta: {
        title() {
          return ''
        }
      }
    },
    {
      path: '/news/page/:page(\\d+)',
      name: 'news',
      component: NewsList,
      meta: {
        title(){
          return ''
        }
      }
    },
    {
      path: '/news/:pk(\\d+)-:slug',
      name: 'news-item',
      component: NewsItem
    },
    {
      path: '/chat/',
      name: 'chat',
      component: Chat,
      meta: {
        title() {
          return ''
        }
      }
    },
    {
      path: '/chat-admin/',
      name: 'chat-admin',
      component: ChatAdmin,
      meta: {
        title() {
          return ''
        }
      }
    },

    {
      path: '/faq',
      name: 'faq_root',
      component: FAQTopicList,
      props: {sidebar: true},
      meta: {
        title() {
          let res = ''
          menus.forEach(function (el) {
            console.log(el);
          })
          return res
        }
      }
    },
    {
      path: '/faq/:slug/',
      name: 'faq',
      component: FAQTopicDetail,
      props: {sidebar: true},
    },

    {
      path: '/:slug(' + paths.join('|') + ')/',
      name: 'article',
      component: Article,
      props: {sidebar: true},
      meta: {
        title: function (){
          console.log('here')
          return 'custom title'

        }
      }
    }
  ]
})
