<template>
  <div class="card panel-orange">
      <div class="card-header">
        <strong>Хотите узнать Вашу цену?</strong>
      </div>
        <div class="card-body">

            <form class=" form-base" id="calc-form">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group form-group-lg">
                            <label for="pc_num" class="control-label">Выберите тариф</label>
                            <select class="form-control" v-model="currentRate">
                              <option v-for="r in rates" v-bind:key="r.id" v-text="r.name" v-bind:value="r.id"></option>

                            </select>
                        </div>
                        <div class="form-group form-group-lg">
                            <label for="pc_num" class="control-label">Количество компьютеров</label>
                            <input type="number"
                                   v-model="pcCount" class="form-control input-lg"
                                   data-def="5" id="pc_num" value="5" min="1">
                        </div>
                        <div class="form-group form-group-lg">
                            <label for="server_num" class="control-label">Количество серверов</label>
                            <input type="number" v-model="serverCount"
                                   class="form-control input-lg" id="server_num"
                                   value="0" data-def="0" min="0">
                        </div>
                        <div class="form-group" style="min-height:74px;">
                            <span style="margin-top:15px;" class="c-white">
                                Стоимость обслуживания компьютеров вашего офиса: <br>
                                <span id="cost-data">
                                  <span class="h2"
                                                           v-text="total"></span>&nbsp;руб. в месяц &nbsp;&nbsp;</span>
                            </span><br>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    <!--  -->
    </div>

</template>
<script>
/*
eslint-disable vue/no-side-effects-in-computed-properties
 */
const rates = JSON.parse(document.getElementById('rates').innerText)
let ratesX = {}

rates.forEach(function (e) {
      ratesX[e.id] = e
    })
export default {
  name: 'Calculator',
  data(){
    return {
      pcCount: 5,
      serverCount: 0,
      cost: 0,
      rates: JSON.parse(document.getElementById('rates').innerText),
      currentRate: rates[0].id,
      rate: null
    }
  },
  mounted() {

  },
  computed: {
    total(){
      this.rate = ratesX[this.currentRate]

      let pcCount = this.pcCount
      let serverCount = this.serverCount
      if(this.pcCount === 0 && this.serverCount ===0){
        return 0
      }
      let total = 0
      let pcPrice = 0
      let serverPrice = 0
      if(pcCount < this.rate.min_amount) {
        pcCount = this.rate.min_amount
      }


      let nums = Object.keys(this.rate.prices_pc)
      if  (pcCount > parseInt(nums[nums.length -1])) {
        pcPrice = this.rate.prices_pc[nums[nums.length -1]]
        console.log('here')
      } else {
        for (let x in this.rate.prices_pc) {
          if (pcCount >= x) {
            pcPrice = this.rate.prices_pc[x];
          }
        }
      }
      nums = Object.keys(this.rate.prices_server)
      serverPrice = this.rate.prices_server[nums[nums.length-1]]
      let _rate
      for (let x in this.rate.prices_server){
          _rate = this.rate.prices_server[x]
          console.log(serverCount, _rate)
          if(serverCount > _rate.min){
              if (_rate.max === null) {
                   serverPrice = _rate
              } else  {
                if(_rate.max >= serverCount) {
                  serverPrice = _rate
                }
              }
          }
      }
      console.log('price', pcPrice, pcPrice*pcCount,  serverCount, serverPrice)
      total = 0
      if (pcCount > 0) {
        total += pcPrice*pcCount
      }
      console.log()
      if (serverCount > 0) {
        total += serverPrice.price*serverCount
      }
      return total
    }
  }
}
</script>
<style>
  .card.panel-orange {
    border-color: #f8b527;
    background-image: url(/static/img/calc.png);
    background-size: cover;
  }

  .panel-orange>.card-header {
    background-image: -webkit-linear-gradient(top,rgba(248,181,39,.6),rgba(248,181,39,.6));
    background-image: -o-linear-gradient(top,rgba(248,181,39,.6) 0,rgba(248,181,39,.6) 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(248,181,39,.6)),to(#f8b527));
    background-image: linear-gradient(180deg,rgba(248,181,39,.6) 0,#f8b527);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(248,181,39,.6),endColorstr=rgba(248,181,39,1),GradientType=0);
}

</style>
