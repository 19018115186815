<template>
  <div id="app">
    <div class="container-xl">
      <div class="">
        <Header :top-menu="topMenu"
                :top-menu-items="topMenuItems" :branch="leftMenu"
                v-on:open-call-form="openCallForm()"
        ></Header>
      </div>
    </div>
    <div class="container-xl ">
      <div class="">
        <template v-if="currentUrl == '/'">
          <div class="row">
            <div class="col-xs-12 overflow-hidden">
              <router-view></router-view>
            </div>
          </div>
          <div id="latest-news" v-if="latestNews.length > 0">
            <h2>Новости</h2>
            <div class="row">
              <div v-for="n in latestNews" :key="n.id" class="col-md-4 col-xs-12 mb-1">
                <h5>
                  <router-link :to="n.url">
                    <template>
                      <span v-html="n.title"></span>
                    </template>
                  </router-link>
                </h5>
                <div class="row">
                  <div class="col-sm-12">
                    <router-link :to="n.url">
                      <template>
                        <img class="img-fluid img-thumbnail rounded" :src="n.image" :alt="n.title">
                      </template>
                    </router-link>
                  </div>
                  <div class="col-sm-12">
                    <div v-text="n.date"></div>
                    <div v-text="n.description"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="leftMenu.children !== undefined && leftMenu.children.length > 0">
          <h1 v-if="currentItem" class="" v-text="currentItem.h1"></h1>
          <div class="row flex-column-reverse flex-lg-row">
            <aside class="sidebar hidden-xs col-sm-4 col-md-3">
              <LeftMenu :branch="leftMenu"></LeftMenu>
            </aside>
            <div class="content col-xs-12 col-sm-8 col-md-9">
              <router-view></router-view>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-xs-12 overflow-hidden">
              <router-view></router-view>
            </div>
          </div>
        </template>
      </div>
      <Callback :canOpen="!serviceOrderOpened" :open="cbOpen" ref="callback">
      </Callback>
    </div>
    <Footer></Footer>
    <button id="scroll-btn" @click="scrollTop">
      <span><font-awesome-icon :icon="['fa', 'angle-up']"/></span>
    </button>
    <ChatIcons></ChatIcons>
    <b-modal id="modal-service" :content-class="'service-bg'"
             title="Закажите наши услуги на сайте и получите 10% скидки!"
             hide-footer
             size="lg"
             @show="onOpenServiceForm"
             @hidden="resetOrderForm"
    >

        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSend)">
            <h1 v-if="serviceOrderSend" class="text-danger">Ваша заявка отправлена</h1>
            <div class="row px-2">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name" class="control-label">Ваше имя</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="name" placeholder="как к Вам обращаться" v-model="serviceOrder.name"
                           type="text" class="form-control input-lg" maxlength="16">
                    <span class="invalid-feedback d-inline">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <ValidationProvider rules="phone" v-slot="{ errors }">
                    <label for="phone" class="control-label">Ваш телефон</label>
                    <masked-input mask="\+\375 (11) 111-11-11" maskChar=" "
                                  placeholder="в формате 375 29 123 4567"
                                  class="form-control input-lg"
                                  id="phone"
                                  v-model="serviceOrder.phone"
                    ></masked-input>
                    <span class="invalid-feedback d-inline">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label for="problem" class="control-label">Описание проблемы</label>
                  <textarea class="form-control input-lg" id="problem"
                            placeholder="Настроить интернет по всей квартире"
                            v-model="serviceOrder.problem"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="text-right">
              <b-button
                  @show="resetOrderForm"
                  @click="$bvModal.hide('modal-service')">Закрыть
              </b-button>
              &nbsp;
              <b-button variant="warning" type="submit">Отправить</b-button>
            </div>
          </form>
        </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Header from "./components/blocks/Header.vue";
import LeftMenu from "./components/blocks/LeftMenu";
import Footer from "./components/blocks/Footer";
import Callback from "./components/blocks/Callback";
import ChatIcons from "./components/blocks/ChatIcons";
import list_to_tree from "./libs/utils.js";
import './app.scss'
import './assets/css/style.css'
import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';

const axios = require('axios')

const topMenuItems = JSON.parse(document.getElementById('menus').innerText).top_menu
const topMenu = list_to_tree(topMenuItems)

extend('required', {
  ...required,
  message: 'Это поле обязательно'
});
extend('phone', {
  validate: function (value) {
    console.log(value === '', value === null)
    if (value.search('_') === -1 && value) {
      return {valid: true, required: true}
    }
    return {valid: false, required: true}
  },
  message: "Введите 9 цифр телефона",
  computesRequired: true
})

export default {
  data: function () {
    return {
      topMenu: topMenu,
      topMenuItems: topMenuItems,
      currentUrl: window.location.pathname,
      leftMenu: {},
      currentItem: null,
      cbOpen: false,
      latestNews: [],
      serviceOrder: {
        name: null,
        phone: null,
        problem: null
      },
      serviceOrderSend: false,
      serviceOrderOpened: false,
    }
  },
  components: {Header, LeftMenu, Footer, Callback, ChatIcons, ValidationProvider, ValidationObserver},
  name: 'App',

  created() {
    // scrollBtn.addEventListener("click", scrollWindow);
  },
  methods: {
    openCallForm() {
      if (this.serviceOrderOpened) {
        return
      }
      this.cbOpen = true
      this.$refs.callback.openModal()
    },
    onOpenServiceForm(){
      this.resetOrderForm()
      this.serviceOrderOpened = true
    },
    resetOrderForm() {
      this.serviceOrder = {
        name: null,
        phone: null,
        problem: null
      }
      this.serviceOrderSend = false
      this.serviceOrderOpened = false
    },
    onSend() {
      let self = this
      let data = JSON.parse(JSON.stringify(self.serviceOrder))
      data.url = window.location.href
      axios.post('/api/service-order/', data).then(function (resp) {
        if (resp.status === 201) {
          self.serviceOrderSend = true
        }
      }).catch(function (err) {
        console.log(err)
      })
    },
    closeCallForm() {
      this.cbOpen = false
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  },
  mounted() {
    let self = this
    if (window.location.pathname === '/' && self.latestNews.length === 0) {
      axios.get('/api/news/?latest=3').then(function (resp) {
        self.latestNews = resp.data.results
      }).catch(function (err) {
        console.log(err)
      })
    } else {
      self.latestNews = []
    }

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.currentUrl = window.location.pathname
        let fullPath = to.fullPath
        if (fullPath.indexOf('/news/') === 0) {
          fullPath = '/news/'
        }
        for (let i = 0; i < topMenu.length; i++) {
          let parent = topMenu[i]
          // console.log(parent.absolute_url, fullPath, parent.absolute_url === fullPath)
          if (parent.absolute_url === fullPath) {
            parent.isActive = true
            this.currentItem = parent
            this.leftMenu = parent;
            return
          }
          for (let j = 0; j < parent.children.length; j++) {
            let child = parent.children[j]

            if (child.absolute_url === fullPath) {
              child.isActive = true
              this.leftMenu = parent
              this.currentItem = child
              return
            }
          }

        }

      }
    }
  }
}

function scrollBtnDisplay() {
  let scrollBtn = document.getElementById('scroll-btn')
  if (!scrollBtn) {
    return
  }
  window.scrollY > 200
      ? scrollBtn.classList.add("show")
      : scrollBtn.classList.remove("show")
}

function scrollWindow() {
  if (window.scrollY != 0) {
    setTimeout(function () {
      window.scrollTo(0, window.scrollY - 50);
      scrollWindow();
    }, 10);
  }
}

window.addEventListener("scroll", scrollBtnDisplay);
</script>

<style>
.card-text.clamped {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.overflow-hidden {
  overflow-x: hidden;
}

#app {
  padding: 0 15px;
}

h1 {
  font-size: 1.75rem
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.15rem;
}

h4 {
  font-size: 1.10rem;
}

h5 {
  font-size: 1.05rem;
}

h6 {

}

.page-item.active .page-link {
  background-color: #BBDDBD;
  border-color: #BBDDBD;
}

#latest-news {
  margin-right: -15px;
  margin-left: -15px;
}

#latest-news > .row {

}

@media screen and (max-width: 768px) {
  .dropdown-menu .dropdown-item {
    white-space: initial !important;
    line-height: 1.1rem;
  }
}

.service-bg {
  background-image: url("/static/img/service-order.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.service-bg .modal-title {
  font-weight: bold;
  color: #5e3408;
}

.hover-rotate {
  -webkit-transition: all .8s;
    transition: all .8s;
}
.bg-sa {
  background: #d47612;
  font-size: 1.8rem;
  height: 3rem;
  line-height: 3rem;
}
.call-btn-mobile {
  bottom: 0px;height: 3rem;left: 0;right: 0;
}
.bg-sa a {
  position: relative;
  color: white;
  transition: all .8s;
    display: block;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

}
.bg-sa a:hover {
  transform: rotate(360deg);
}

</style>
