<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="row  flex-column-reverse flex-lg-row">
    <div class="col-xs-12 w-100 mb-4 pt-2">
      <ul class="list-group" v-if="branch">
        <li class="list-group-item">
          <h5>
            <router-link
                :to="branch.absolute_url"
                >{{ branch.title }}</router-link></h5>
        </li>
        <li v-for="child in branch.children" v-bind:key="child.id"
            v-if="!child.private || child.private && userStatus.isSuperuser"
            class="list-group-item"
            :class="url== '/' + child.slug + '/' || child.absolute_url == '/news/' && newsItem ? 'bg-left-active' : ''">
          <router-link :to="'/' + child.slug + '/'"
          >{{ child.title }}</router-link>

        </li>
      </ul>
    </div>
    <div class="col-xs-12 w-100">
      <Calculator v-if="branch.id===70"></Calculator>
      <CloudCalculator v-if="branch.id===78"></CloudCalculator>
      <NetworkCalculator v-if="branch.id===82"></NetworkCalculator>
    </div>
  </div>
</template>
<script>
import Calculator from "./Calculator";
import CloudCalculator from "./CloudCalculator";
import NetworkCalculator from "./NetworkCalculator";

const userStatus = JSON.parse(document.getElementById('userStatus').innerText)
export default {
  components: {Calculator, CloudCalculator, NetworkCalculator},
  name: 'LeftMenu',
  props: {
    branch: null
  },
  data() {
    return {
      links: [],
      url: null,
      newsItem: false,
      userStatus: userStatus

    }
  },
  mounted() {
    this.url = window.location.pathname
    if(window.location.pathname.indexOf('/news/') === 0) {
      if (this.$route.name === 'news-item') {
        this.newsItem = true
      }
    }
  },
  watch: {
    $route: function (to, from) {
      console.log(to, from)
      this.url = to.fullPath
      if(window.location.pathname.indexOf('/news/') === 0 && this.$route.name === 'news-item') {
        this.newsItem = true
      } else  {
        this.newsItem = false
    }

    }
  }
}
</script>
