<template>

    <div class="row">
      <div class="col-md-12">
        <h1>Советы сисадмина, выбери свою тему:</h1>
        <ul v-if="topics" class="list-group">
          <li v-for="x in topics" v-bind:key="x.id" class="list-group-item">
            <router-link :to="x.url">{{x.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  
</template>
<script>

const axios = require('axios')
let articlesX = {}
const allArticles = JSON.parse(document.getElementById('articles').innerText)
for (let c = 0; c < allArticles.length; c++) {
  allArticles[c].content = null
  let _path = allArticles[c].path

  articlesX[_path] = allArticles[c]
}

export default {
  name: 'FAQTopicList',
  components: {},
  data() {
    return {
      topics: []

    }
  },
  mounted() {
    let self = this
    axios.get('/api/faq-topic-list/').then(function (resp) {
      self.topics = resp.data
    }).catch(function (err) {
      console.log(err)

    })

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        console.log(to)


      }
    }
  },
}
</script>
