<template>
  <div>
    <h1>Новости от сисадмина</h1>
    <p><strong>IT НОВОСТИ</strong>&nbsp;свежие и не очень читайте здесь, пишите нам или звоните&nbsp;</p>
    <div v-for="n in items" :key="n.id" class="mb-4">
      <template v-if="n.image === null">
        <h2>
          <a :href="n.url" v-text="n.title"></a>
        </h2>
        <p class="mb-2"><strong v-text="n.human_date"></strong></p>
        <p v-text="n.description"></p>
        <div class="blog-list-detail">
          <a :href="n.url">читать далее</a>
        </div>
      </template>
      <template v-else>
        <h2>
          <a :href="n.url" v-text="n.title"></a>
        </h2>
        <p class="mb-2"><strong v-text="n.human_date"></strong></p>
        <div class="row">
          <div class="col-md-3 col-sm-12">
          <img class="img-fluid" :src="n.image">
        </div>
        <div class="col-md-9 col-sm-12">
          <p v-text="n.description"></p>
          <div class="blog-list-detail">
            <a :href="n.url">читать далее</a>
          </div>
        </div>

        </div>

      </template>
    </div>
    <ul class="pagination" v-if="pagination.previous || pagination.next">
      <li class="page-item">
        <a class="page-link">Страница {{ pagination.current }} из {{ pagination.count }}</a>
      </li>
      <li class="page-item" :class="pagination.previous === null ? 'disabled': ''">
        <a class="page-link" v-if="pagination.previous !== null" :href="pagination.previous">←</a>
        <a v-else class="page-link">←</a>
      </li>
    </ul>
    <paginate v-if="pagination.count > 1"
              :page-count="pagination.count"
              :marginPages="3"
              :pageClass="'page-item'"
              :prevClass="'page-item'"
              :nextClass="'page-item'"
              :pageLinkClass="'page-link'"
              :prevLinkClass="'page-link'"
              :nextLinkClass="'page-link'"
              :click-handler="clickHandler"
              :prev-text="'Предыдущая'"
              :next-text="'Следующая'"
              :container-class="'pagination'"
              :value="page"
    ></paginate>
  </div>
</template>
<script>

const axios = require('axios')

export default {
  name: 'News',
  components: {},
  props: {

  },

  data() {
    return {
      leftMenu: [],
      items: [],
      pagination: {},
      paginationDisplay: [],
      page: this.$route.params.page === undefined? 1 : parseInt(this.$route.params.page)
    }
  },


  mounted() {
    this.getItems()
  },
  created() {

  },
  methods: {
    clickHandler(pageNum) {
      console.log(pageNum)
      this.page = pageNum
      this.getItems()

    },
    getItems() {
      let self = this
      let url = '/api/news/'

      if (self.page !== 1) {
        url += '?page=' + self.page
      }
      axios.get(url).then(function (resp) {
        self.items = resp.data.results
        self.pagination = resp.data.pagination

      }).catch(function (err) {
        console.log(err)
      })
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        console.log(to)
      }
    }
  }
}
</script>
