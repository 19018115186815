<template>
  <div>
    <b-modal v-model="show" size="lg" id="call-modal"
             ref="simple-call-modal" hide-footer
             title="Заказать звонок"
             @show="resetForm"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <h1 class="text-danger" v-if="beenSent">Ваша заявка отправлена</h1>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group form-group-lg">
                <label class="d-block" for="id-name-0">Ваше имя</label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input type="text" id="id-name-0" class="form-control input-lg" v-model="name">
                  <span class="invalid-feedback d-inline">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="form-group"><label class="control-label" for="id-phone-0">Ваш телефон</label>
                  <ValidationProvider rules="phone" v-slot="{ errors }">
                    <masked-input mask="\+\375 (11) 111-11-11" maskChar=" "
                                  placeholder="в формате 375 29 123 4567"
                                  class="form-control input-lg"
                                  id="id-phone-0"
                                  v-model="phone"
                    ></masked-input>
                    <span class="invalid-feedback d-inline">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-sm-7">
                <h4>Оставьте свой номер телефона, и мы свяжемся с Вами!</h4>
              </div>
              <div class="col-sm-5">
                <button type="button" class="btn btn-grey mr-2" @click="onClose()">Закрыть</button>
                <button type="submit" class="btn btn-warning" >Отправить</button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>
<script>
import {ValidationProvider, extend, ValidationObserver} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';

extend('phone', {
  validate: function (value) {
    console.log(value === '', value === null)
    if (value.search('_') === -1 && value) {
      return {valid: true, required: true}
    }
    return {valid: false, required: true}
  },
  message: "Введите 9 цифр телефона",
  computesRequired: true
})
extend('required', {
  ...required,
  message: 'Это поле обязательно'
});
const axios = require('axios')
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

export default {
  comments: {},
  components: {ValidationProvider, ValidationObserver},
  name: 'Callback',
  props: {
    open: {
      type: Boolean,
    },
    canOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phone: '',
      name: '',
      currentUrl: null,
      show: false,
      errors: {},
      beenSent: false
    }
  },
  created() {
    this.show = this.open
  },
  methods: {
    resetForm(){
      this.beenSent = false;
      this.phone = ''
      this.name = ''
    },
    openModal() {
      // this.$refs['simple-call-modal'].show()
      this.show = true
    },
    submit() {
      console.log(this.errors)
      let self = this
      if (!self.name) {
        document.getElementById('id-name-0').classList.add('is-invalid')
        return
      }
      if (!self.phone) {
        document.getElementById('id-phone-0').classList.add('is-invalid')
        self.errors.phone = 'Поле обязательно'
        console.log(self.errors)
        return
      }

      axios.post('/api/simple-call/', {remote_name: self.name, phone: self.phone})
          .then(function (resp) {
            console.log(resp.data)
            if (!resp.data.ok) {
              self.errors = resp.data.errors
            } else {
              self.beenSent = true
              self.show = false
              setTimeout(function () {

              }, 2000)
            }
          })
          .catch(function (err) {
            console.log(err);
          })
    },
    onClose() {
      let self = this
      self.show = false
      setTimeout(function () {
        if (!this.canOpen) {
          return
        }
        self.$refs['simple-call-modal'].show()
        self.show = true
      }, 3*60*1000)
    }
  },
  mounted() {
    let self = this;
    this.$root.$on('open-modal', function () {
      self.openModal()
    });
    setTimeout(function () {
      if (!self.canOpen) {
        return
      }
      self.show = true
    }, 3*60*1000)
  },
  computed: {},
  watch: {
    open: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.show = true
          this.$refs['simple-call-modal'].show()
        }
      }
    },
  }
}
</script>
<style>
#call-modal .modal-content {
  background-size: cover;
  background-image: url(/static/img/call-me.png);
}
</style>
