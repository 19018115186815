<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="">
    <span v-if="tminus">{{tminus}}</span>
  </div>
</template>
<script>

const  moment = require('moment')

export default {
  components: {},
  comments: {},
  name: 'CountdownTimer',
  props: ['end'],
  data() {
    return {
      tminus: null,
      counter: null,
    }
  },
  created() {

  },
  mounted() {
    console.log(this.end)
    this.startCountdown();
  },
  computed: {},
  watch: {},
  methods: {
    timeDifference() {
      let compiledString = [],
          days = 0,
          hours = 0,
          minutes = 0,
          seconds = 0,
          deduceSeconds = 0,
          now = moment(), dt = moment(this.end);

      if (!this.end) {
        return;
      }

      deduceSeconds = Math.floor(dt.diff(now)/1000);

      if (deduceSeconds < 0) {
        this.stopCountdown();
        return;
      }
      //determine if the seconds are greater than a day
      if (deduceSeconds >= 86400) {
        days = Math.floor(deduceSeconds / 86400);
        if (days > 0) {
          deduceSeconds = deduceSeconds % (days * 3600);
        } else {
          days = 0;
        }
      }
      //determine if the seconds are greater than an hour
      if (deduceSeconds >= 3600) {
        hours = Math.floor(deduceSeconds / 3600);
        if (hours > 0) {
          deduceSeconds = deduceSeconds % (hours * 3600);
        } else {
          hours = 0;
        }
      }
      //determine if the seconds are greater than a minute
      if (deduceSeconds >= 60) {
        minutes = Math.floor(deduceSeconds / 60);
        if (minutes > 0) {
          deduceSeconds = deduceSeconds % (minutes * 60);
        } else {
          minutes = 0;
        }
      }
      seconds = deduceSeconds;
      compiledString.push(days <= 0 ? "" : days + " дней");
      compiledString.push(hours <= 0 ? "" : hours + " часов");
      compiledString.push(minutes <= 0 ? "" : minutes + " минут");
      compiledString.push(seconds < 0 ? "" : seconds + " секунд");
      compiledString = compiledString.join(" ");
      if (compiledString !== "") {
        this.tminus = compiledString;
        return;
      }
      this.stopCountdown();
      return;
    },
    startCountdown: function () {
      this.counter = setInterval(() => {
        this.timeDifference();
      }, 1000);
    },
    stopCountdown: function () {
      this.tminus = false;
      this.counter = clearInterval(() => {
        this.timeDifference();
      });
    }
  }
}
</script>
