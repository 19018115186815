<template>
  <div class="container-xl px-sm-1 px-xs-1">
    <div class="row">
      <div class="col-md-12">
        <h1>Вопросы и ответы на тему:</h1>
        <div v-if="topic">
          <h2 v-text="topic.name"></h2>
          <dl>
            <template v-for="(q, j) in topic.questions" >
              <dt v-html="q.text" :key="'dt-'+j"></dt>
              <dd v-html="q.answer" :key="'dd-'+j"></dd>
            </template>

          </dl>
        </div>


      </div>
    </div>
  </div>
</template>
<script>

const axios = require('axios')
let articlesX = {}
const allArticles = JSON.parse(document.getElementById('articles').innerText)
for (let c = 0; c < allArticles.length; c++) {
  allArticles[c].content = null
  let _path = allArticles[c].path

  articlesX[_path] = allArticles[c]
}

export default {
  name: 'FAQTopicDetail',
  components: {},
  data() {
    return {
      topic: null
    }
  },
  mounted() {
    let slug = this.$route.params.slug, self = this
    axios.get('/api/faq-topic-details/'+slug+'/').then(function (resp){
      console.log(resp)
      self.topic = resp.data
    }).catch(function (err) {
      console.log(err)
    })

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        console.log(to)


      }
    }
  },
}
</script>
