<template>
  <div class="card panel-orange">
    <div class="card-header">
      <strong>Хотите узнать Вашу цену?</strong>
    </div>
      <div class="card-body">
        <form class=" form-base" id="cloud-form">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group form-group-lg">
                <label for="os" class="control-label">Операционная система</label>
                <select id="os" v-model="os" class="form-control">
                  <option v-for="r in config.os" v-bind:key="r.id" v-text="r.label" :value="r.id"></option>
                </select>
              </div>
              <div class="form-group form-group-lg">
                <label for="server" class="control-label">Объём нагрузки</label>
                <select id="server" class="form-control" v-model="server">
                  <option v-for="r in config.server" v-bind:key="r.id" v-text="r.label" :value="r.id"></option>
                </select>
              </div>
              <div class="form-group form-group-lg">
                <label for="backup" class="control-label">Объём бэкапов</label>
                <select id="backup" class="form-control" v-model="backup">
                  <option v-for="r in config.backup" v-bind:key="r.id"
                          v-text="r.label" :value="r.id"></option>
                </select>
              </div>
            </div>
          </div>
          <h6>параметры вашего сервера:</h6>
              <dl class="row">
                <dt class="col-sm-8 text-right pr-1 font-weight-normal">CPU, ядер:</dt>
                <dd class="col-sm-4 pl-0 h5">{{serverConfig.cpu}}</dd>
                <dt class="col-sm-8 text-right pr-1 font-weight-normal">RAM, ГБ:</dt>
                <dd class="col-sm-4 pl-0 h5">{{serverConfig.ram}}</dd>
                <dt class="col-sm-8 text-right pr-1 font-weight-normal">SSD, ГБ:</dt>
                <dd class="col-sm-4 pl-0 h5">{{serverConfig.ssd}}</dd>
              </dl>
          <h6 class="text-right">Стоимость аренды</h6>
          <span id="cost-data">
            <span class="h2" v-text="total"></span>&nbsp;руб. в месяц &nbsp;&nbsp;</span>


        </form>
    </div>
  </div>
</template>
<script>
const rates = JSON.parse(document.getElementById('cloud').innerText)
let server = null
rates.server.forEach(function (el) {
  if (el.default) {
    server = el.id
  }
})
let os = null
rates.os.forEach(function (el) {
  if (el.default) {
    os = el.id
  }
})
let backup = null
rates.backup.forEach(function (el) {
  if (el.default) {
    backup = el.id
  }
})
export default {
  name: 'CloudCalculator',
  data(){
    return {
      config: rates,
      server: server,
      os: os,
      backup: backup
    }
  },
  mounted() {
    console.log(this.os)
  },
  computed: {
    total(){
      let res = 0
      for (let c=0; c<this.config.server.length; c++){
        if(this.config.server[c].id === this.server) {
          res += this.config.server[c].price
          break
        }
      }
      for (let c=0; c<this.config.backup.length; c++){
        if(this.config.backup[c].id === this.backup) {
          res += this.config.backup[c].price
          break
        }
      }
      return res
    },
    serverConfig(){
      for (let c=0; c<this.config.server.length; c++){
        if(this.config.server[c].id === this.server) {
          return this.config.server[c]
        }
      }
      return null
    }
  }
}
</script>
<style>
  .card.panel-orange {
    border-color: #f8b527;
    background-image: url(/static/img/calc.png);
    background-size: cover;
  }

  .panel-orange>.card-header {
    background-image: -webkit-linear-gradient(top,rgba(248,181,39,.6),rgba(248,181,39,.6));
    background-image: -o-linear-gradient(top,rgba(248,181,39,.6) 0,rgba(248,181,39,.6) 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(248,181,39,.6)),to(#f8b527));
    background-image: linear-gradient(180deg,rgba(248,181,39,.6) 0,#f8b527);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(248,181,39,.6),endColorstr=rgba(248,181,39,1),GradientType=0);
}
#cloud-form dt {
  /*line-height: 2.75rem;*/
}

</style>
