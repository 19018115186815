<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="">
    <div class="hr"></div>
    <div class="row footer-menu">
      <div class="container-xl">
        <div class="row">
          <div class="pr-1 pl-1 col-sm-12 col-md-3" v-for="(m, idx) in menuItems" :key="m.id" v-if="idx < 3">
            <h5><router-link v-text="m.title" :to="m.absolute_url"></router-link></h5>
            <div v-for="c in m.children" :key="c.id" class="d-none d-md-block">
              <a v-text="c.title" :href="c.absolute_url"></a>
            </div>
          </div>
          <div class="pr-1 pl-1 col-sm-12 col-md-3">
            <h5 v-for="(m, idx) in menuItems" :key="m.id" v-if="idx >= 3">
              <a v-text="m.title" :href="m.absolute_url"></a>
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-4">
      <div class="col-md-6">

      </div>
      <div class="col-md-6">
        <p class="site-info text-left">
          © <span>«Системный администратор»</span> 2010,
          <span>Тел. 8(017)-380-45-22</span><br>
          <span>Режим работы: Пн-Пт: 9.00–18.00 <span class="holiday">Сб-Вс: Выходной</span></span>
        </p>
      </div>
    </div>
    <div class="row d-lg-none d-md-none  position-fixed call-btn-mobile" style="bottom: 0">
      <div class="col-12 hover-rotate bg-sa">
        <a href="tel:+3753898980" class="d-block text-center">
          <font-awesome-icon :icon="['fas', 'phone']" />
        </a>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>
<script>

import list_to_tree from "@/libs/utils"
import YandexShare from '@cookieseater/vue-yandex-share';
import { library } from '@fortawesome/fontawesome-svg-core'
import {faPhone} from '@fortawesome/free-solid-svg-icons'

library.add(faPhone)

const menuItems = JSON.parse(document.getElementById('menus').innerText)
export default {
  components: {},
  comments: {YandexShare, },
  name: 'Footer',
  props: {},
  data() {
    return {
      phones: [],
      menuItems: list_to_tree(menuItems.top_menu),
      currentBranch: null,
      currentUrl: null
    }
  },
  created() {

  },
  mounted() {
  },
  computed: {},
  watch: {
    $route: function () {

      let el = document.getElementById('header-block')
      window.scrollTo({top: el.scrollHeight, left: 0, behavior: 'smooth'})
    }
  }
}
</script>
<style>
.hr {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='9'%3E%3Crect width='100' height='9' style='fill:%23ffffff;stroke:none'/%3E%3Cpolygon points='9,0 50,0 41,9 0,9' style='fill:%23b8dbbd;stroke:none;'/%3E%3Cpolygon points='59,0 100,0 91,9 50,9' style='fill:%23e9f3bb;stroke:none;'/%3E%3C/svg%3E%0A") 0 0 repeat-x;
  height: 9px;
  margin: 3em 0;
}
.footer-menu {
    background: #ef9c43 !important;
}
.footer-menu a {
  color: #fff;
}
.holiday {
    color: red;
}

</style>
