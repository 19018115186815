<template>
  <div class="card panel-orange">
    <div class="card-header">
      <strong>Узнайте цену Вашей сети</strong>
    </div>
    <div class="card-body">
      <form class=" form-base" id="network-form">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-lg">
              <label for="network-type" class="control-label">Выберите тип сети</label>
              <select id="network-type" v-model="networkType" class="form-control"
                      @change="changeNetworkType()">
                <option v-for="t in config.types"
                        :value="t.id" :key="t.id" v-text="t.label"></option>
              </select>
            </div>
            <div class="form-group form-group-lg">
              <label for="quantity" class="control-label">Количество точек (компьютеров)</label>
              <input id="quantity" type="number" step="1"
                     min="1" max="999" v-model="quantity" class="form-control">
            </div>
            <div class="form-group form-group-lg">
              <label for="wiring-type" class="control-label">Тип монтажа</label>
              <select id="wiring-type" class="form-control" v-model="wiringType">
                <option v-for="t in wiringTypes" :key="t.id"
                        :value="t.id" v-text="t.label"></option>
              </select>
            </div>
            <div class="form-group text-right">
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="setup-y">Да</label>
                <input class="form-check-input" type="radio" id="setup-y" value="true" v-model="setup">
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="setup-n">Нет</label>
                <input class="form-check-input" type="radio" id="setup-n" value="false" v-model="setup">
              </div>

            </div>
          </div>
        </div>
        <h6>Стоимость организации сети</h6>
        <h3 class="text-right font-weight-bold h4"><small>{{ total }}&nbsp;руб.</small></h3>
      </form>
    </div>
  </div>
</template>
<script>
const config = JSON.parse(document.getElementById('network').innerText)
let nt = config.types[0].id
let networkTypesX = {}
config.types.forEach(function (el) {
  networkTypesX[el.id] = el
})
let wt = config.types[0].wiring_types[0].id
// console.log(networkTypesX)
export default {
  name: 'NetworkCalculator',
  data() {
    return {
      config: config,
      networkType: nt,
      wiringType: wt,
      quantity: 1,
      setup: 'true'
    }
  },
  mounted() {
  },
  methods: {
    changeNetworkType() {
      let types = networkTypesX[this.networkType].wiring_types
      console.log(types[0])
      let ids = []
      types.forEach(function (el) {
        ids.push(el.id)
      })

      // if (ids.indexOf(this.wiringType) === -1){
      //   this.wiringType = ids[0]
      // }
      this.wiringType = types[0].id
    }
  },
  computed: {
    total() {
      let self = this
      let base = config.base
      let multi = 1
      let ignoreSetup = false

      networkTypesX[this.networkType].wiring_types.forEach(function (el) {
        if (el.id === self.wiringType) {
          multi = multi * el.multi
          if (el.ignore_setup) {
            ignoreSetup = true
            console.log(el)
          }
        }
      })
      if (!ignoreSetup) {
        if (this.setup === 'true') {
          multi = multi * networkTypesX[this.networkType].multi_with_setup
        } else {
          multi = multi * networkTypesX[this.networkType].multi_without_setup
        }

      }
      // console.log(this.quantity * multi * base)
      return Math.floor(this.quantity * multi * base)
    },
    wiringTypes() {
      return networkTypesX[this.networkType].wiring_types
    }
  }
}
</script>
<style>
.card.panel-orange {
  border-color: #f8b527;
  background-image: url(/static/img/calc.png);
  background-size: cover;
}

.panel-orange > .card-header {
  background-image: -webkit-linear-gradient(top, rgba(248, 181, 39, .6), rgba(248, 181, 39, .6));
  background-image: -o-linear-gradient(top, rgba(248, 181, 39, .6) 0, rgba(248, 181, 39, .6) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(248, 181, 39, .6)), to(#f8b527));
  background-image: linear-gradient(180deg, rgba(248, 181, 39, .6) 0, #f8b527);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(248, 181, 39, .6), endColorstr=rgba(248, 181, 39, 1), GradientType=0);
}

</style>
