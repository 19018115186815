<template>
  <div class="row">
    <div class="pl-3 w-100">
      <div class="chat col-xs-12" v-if="thread !== null">
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
        <div class="chat-header">
          <h1>On-line консультант от Сисадмина</h1>
        </div>
        <div class="chat-body">
          <div class="message reply">
            <div v-text="greetingText"></div>
          </div>
          <div v-for="m in thread.messages" v-bind:key="m.id" class="message" :class="m.is_reply? 'reply': ''">
            <div v-text="m.text"></div>
            <div class="text-right">
              <span v-text="m.is_reply? 'Cисадмин' : 'Я'"></span>
              <span class="ts" v-text="formatTime(m.ts)" v-if="m.ts"></span>
            </div>


          </div>
        </div>
        <div class="chat-footer ">
          <div class="form-group">
            <textarea v-model="text" placeholder="Введите сообщение" class="form-control"></textarea>
          </div>
          <div class="text-right form-group">
            <button class="btn btn-warning" @click="sendMessage()">Отправить</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
const axios = require('axios')
const moment = require('moment')

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

export default {
  name: 'Chat',
  components: {},
  props: {},

  data() {
    return {
      leftMenu: {},
      thread: null,
      ws: null,
      text: '',
      inter: null
    }
  },

  mounted() {
  },
  created() {
    let self = this
    self.inter = setInterval(function (){
      self.getMessages()
    }, 1500)
  },
  updated() {
    let el = document.querySelector('.chat-body')
    if (el.scrollHeight > el.offsetHeight) {
      el.scroll(0, el.scrollHeight)
    }
  },
  methods: {
    getMessages() {
      let self = this
      axios.get('/api/chat/').then(function (resp) {
        self.thread = resp.data
      }).catch(function (err) {
        console.log(err.response.data)
      })
    },
    sendMessage() {
      let self = this
      axios.post('/api/chat/', {
        text: self.text,
        thread: self.thread.uuid
      }).then(
          function (resp) {
            self.text = ''
            self.thread = resp.data
          }
      ).catch(function (err) {
        console.log(err)
      })
    },
    formatTime(ts) {
      return moment(ts).format('HH:mm')
    },
    isWorkTime() {
      let now = moment()
      let start = moment('09:15:00Z+03:00', 'hh:mm:ss:ZZ')
      let end = moment('17:15:00Z+03:00', 'hh:mm:ss:ZZ')
      return now.isBetween(start, end) && now.day() < 6
    },

  },
  computed: {
    watchingWorkingTime() {
      return this.isWorkTime()
    },
    greetingText() {
      if (this.isWorkTime()) {
        return 'Сисадмин on-line.  какой у вас вопрос?'
      } else {
        return 'Сисадмин отдыхает, заходите к нам в рабочее время!'
      }
    }

  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to === undefined) {
          return;
        }

      }
    }
  }
}
</script>
<style>
.chat-header {
  background: #fff;
  margin: -1rem;
  padding: 1rem;
}

.chat {
  padding: 10px;
  background: #FCF8DB;
}

.chat-body {
  padding: 1rem;
  height: 400px;
  overflow-y: auto;
  margin-right: -10px;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: 15px;
}

.chat-body::-webkit-scrollbar {
  height: 12px;
  width: 6px;
  background: #e0e0e0;
}

.chat-body::-webkit-scrollbar-thumb {
  background: #6969dd;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.chat-body::-webkit-scrollbar-corner {
  background: #6969dd;
}

.chat-footer {
  margin-top: 20px;
}

.message {
  max-width: 75%;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 3px;
  margin-right: 15px;
  float: right;
  clear: both;
}

.message.reply {
  float: left;
  background: #EFFDDE;
}
</style>
